import React, { useState, useEffect } from 'react';
import orderIdIcon from '../assets/images/OrderId.svg';
import DeliveryIcon from '../assets/images/DeliveryStatus.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faIndianRupeeSign, faMinus } from '@fortawesome/free-solid-svg-icons';
import './component.css';
import axios from 'axios';
import axiosURL from '../config';

const PaymentCard = ({ orderId, dateTime, medicines, quantity, location, totalAmount, status }) => {

    const [medicine, setMedicine] = useState([]);

    totalAmount = parseFloat(totalAmount).toFixed(2);
    const platformFeePercentage = 5;
    const platformFee = (totalAmount * platformFeePercentage) / 100;
    const amountEarned = totalAmount - platformFee;

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchAndSetProducts = async () => {
            if (medicines && Array.isArray(medicines)) {
                try {
                    await fetchProductDetails(medicines, quantity);
                } catch (error) {
                    console.error('Error processing products:', error);
                }
            }
        };
        fetchAndSetProducts();
    }, [medicines]);

    const fetchProductDetails = async (productIds, quantities) => {
        try {
            const productsData = [];
            for (let i = 0; i < productIds.length; i++) {
                const id = productIds[i];
                const quantity = quantities[i];
    
                const response = await axiosURL.get('product/getbyid', {
                    params: { id: id }
                });
    
                if (response.status === 200) {
                    const product = response.data[0];
                    product.Quantity = quantity;
                    productsData.push(product);
                } else {
                    console.error(`Failed to fetch details for product ID: ${id}`);
                }
            }
            setMedicine(productsData);
        } catch (error) {
            console.error('Error fetching product details:', error);
            setMedicine([]);
        }
    };    

    const handleViewOrder = () => {
        setShowModal(true);
    }

    const handleBackdropClick = (event) => {
        if (event.target.classList.contains('modal')) {
            setShowModal(false);
        }
    };

    const getStatus = (status) => {
        switch (status) {
            case 0:
                return 'Unpaid';
            case 1:
                return 'Processing';
            case 2:
                return 'Paid';
            default:
                return 'Unknown';
        }
    };

    return (
        <div>
            <div className="ongoing-order-card" style={{ marginBottom: '10px' }}>
                <div className="ongoing-order-info">
                    <div className="ongoing-order-id">
                        <div className="ongoing-order-id-icon">
                            <img src={orderIdIcon} style={{ width: '30px', height: '30px' }} alt="Order ID Icon" />
                        </div>
                        <div>
                            <span>{orderId}</span>
                            <div className="ongoing-order-date-time">
                                <div className="order-date" style={{ fontSize: 14 }} >{dateTime}</div>
                            </div>
                        </div>
                        <div className={`status-box status-paid`}>
                            <div className={`status-box status-${getStatus(status).toLowerCase()}`}>
                                {getStatus(status) === 'Paid' ? (
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                ) : getStatus(status) === 'Processing' ? (
                                    <FontAwesomeIcon icon={faClock} />
                                ) : (
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                )}
                                {' '}{getStatus(status)}
                            </div>
                        </div>
                    </div>
                </div>
                <button className="view-order-btn" onClick={handleViewOrder}>View Order</button>
            </div>
            {showModal && (
                <div className="modal" onClick={handleBackdropClick}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: 'none' }}>
                            <div className="order-info">
                                <div className="order-id">
                                    <div className="order-id-icon">
                                        <img src={orderIdIcon} style={{ width: '30px', height: '30px' }} alt="Order ID Icon" />
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: '600' }}>{orderId}</span>
                                        <div className="order-date-time" style={{ fontWeight: '600' }}>
                                            <span>{dateTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                        <table className="medicine-table">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'left' }}>Items</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {medicine.map((medicine, index) => (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'left' }}>{medicine.name}</td>
                                            <td>{medicine.Quantity}</td>
                                            <td>{medicine.MRP}</td>
                                            <td>{medicine.MRP}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='custom-hr'></div>
                            <div className="modal-summary">
                                <div className="summary-item">
                                    <span>Total Amount:</span>
                                    <span>
                                        <FontAwesomeIcon icon={faIndianRupeeSign} /> {totalAmount}
                                    </span>
                                </div>
                                <div className="summary-item">
                                    <span>Platform Fee ({platformFeePercentage}%):</span>
                                    <span>
                                        <FontAwesomeIcon icon={faMinus} /> <FontAwesomeIcon icon={faIndianRupeeSign} /> {platformFee.toFixed(2)}
                                    </span>
                                </div>
                                <div className="summary-item">
                                    <span>Amount Earned:</span>
                                    <span style={{ fontSize: '24px', fontWeight: '700' }}>
                                        <FontAwesomeIcon icon={faIndianRupeeSign} />{amountEarned.toFixed(2)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PaymentCard;