import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../pharma.css';
import MapPicker from '../../components/MapPicker';
import { Modal } from 'react-bootstrap';
import axiosURL from '../../config';

function MediProfile() {
    const [pharmaDetail, setPharmaDetails] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [mediformData, setMediFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [LicenseImg, setLicenseImage] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [location, setLocation] = useState(null);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const fetchPharmaDetails = async () => {
            try {
                const pharmaId = localStorage.getItem('pharmaId');
                if (!pharmaId) {
                    console.error('No Pharmacy Id found in local storage');
                    return;
                }
                const response = await axiosURL.get(`pharma/getPharmaById?id=${pharmaId}`);
                const details = response.data[0];
                setPharmaDetails(details);
                setMediFormData({
                    name: details.name,
                    password: '',
                    phoneNumber: details.phoneNumber,
                    licenseNo: details.licenseNo,
                    address: details.address,
                    location: details.location,
                    bankName: details.bankName,
                    accountNumber: details.accountNumber,
                    ifscCode: details.ifscCode,
                    accountHolderName: details.accountHolderName,
                });
            } catch (error) {
                console.error('Error fetching doctor details:', error);
            }
        };

        fetchPharmaDetails();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMediFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleLocationSelect = (location) => {
        setMediFormData((prevData) => ({
            ...prevData,
            location: location,
        }));
        setLocation(location);
        console.log(location);
    }
    
    const handleSave = async () => {
        try {
            const pharmaId = localStorage.getItem('pharmaId');
            if (!pharmaId) {
                console.error('No medical ID found in local storage');
                return;
            }
            const formData = new FormData();
            if (LicenseImg) {
                formData.append('file', LicenseImg);
            }
            Object.keys(mediformData).forEach(key => {
                formData.append(key, mediformData[key]);
            });
            formData.append('id', pharmaId);
            await axiosURL.post('pharma/update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });    
            setPharmaDetails((prevDetails) => ({
                ...prevDetails,
                ...mediformData
            }));
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating medical details:', error);
        }
    };
    

    const handleCancel = () => {
        setIsEditing(false);
    };

    const renderMedicalStep1 = () => (
        <>
            <div className="form-group">
                <label>Name</label>
                <input
                    type="text"
                    name="name"
                    value={mediformData.name}
                    placeholder='Enter Your Name'
                    onChange={handleInputChange}
                />
                {errors.name && <span className="error-message">{errors.name}</span>}
            </div>
    
            <div className="form-group">
                <label>Password</label>
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    name="password"
                    value={mediformData.password}
                    onChange={handleInputChange}
                    placeholder='Enter Password'
                />
                {errors.password && <span className="error-message">{errors.password}</span>}
            </div>
    
            <div className="form-group">
                <label>Phone Number</label>
                <input
                    type="text"
                    name="phoneNumber"
                    value={mediformData.phoneNumber}
                    placeholder='Enter Your Phone Number'
                    onChange={handleInputChange}
                />
                {errors.phoneNumber && <span className="error-message">{errors.phoneNumber}</span>}
            </div>
    
            <div className="form-group">
                <label>License Number</label>
                <input
                    type="text"
                    name="licenseNo"
                    value={mediformData.licenseNo}
                    placeholder='Enter Your License Number'
                    onChange={handleInputChange}
                />
                {errors.licenseNo && <span className="error-message">{errors.licenseNo}</span>}
            </div>
    
            <div className="form-group">
                <label>Address</label>
                <input
                    type="text"
                    name="address"
                    value={mediformData.address}
                    placeholder='Enter Your Address'
                    onChange={handleInputChange}
                />
                {errors.address && <span className="error-message">{errors.address}</span>}
            </div>
    
            <div className="register-group">
                <label>Location</label>
                <button
                    type="button"
                    className="location-picker-button"
                    onClick={openModal}
                >
                    <i className="fas fa-location-arrow"></i>
                </button>
                {mediformData.location && (
                    <div className="location-address">
                        <p>Store Address: {location.address}</p>
                    </div>
                )}
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Select Location"
                    className="Modal__Content"
                    overlayClassName="Modal__Overlay"
                >
                    {console.log("Modal is open")}
                    <h2 className="Modal__Title">Select Medical Store Location</h2>
                    <MapPicker
                        onLocationSelect={handleLocationSelect}
                        onClose={closeModal}
                    />
                    <button className="Modal__CloseButton" onClick={closeModal}>
                        &times;
                    </button>
                </Modal>
            </div>
        </>
    );

    const renderMedicalStep2 = () => (
        <>
            <div className="form-group">
                <label>Bank Name</label>
                <input
                    type="text"
                    name="bankName"
                    value={mediformData.bankName}
                    placeholder='Enter Your Bank Name'
                    onChange={handleInputChange}
                />
                {errors.bankName && <span className="error-message">{errors.bankName}</span>}
            </div>
    
            <div className="form-group">
                <label>Account Number</label>
                <input
                    type="text"
                    name="accountNumber"
                    value={mediformData.accountNumber}
                    placeholder='Enter Your Account Number'
                    onChange={handleInputChange}
                />
                {errors.accountNumber && <span className="error-message">{errors.accountNumber}</span>}
            </div>
    
            <div className="form-group">
                <label>IFSC Code</label>
                <input
                    type="text"
                    name="ifscCode"
                    value={mediformData.ifscCode}
                    placeholder='Enter Your IFSC Code'
                    onChange={handleInputChange}
                />
                {errors.ifscCode && <span className="error-message">{errors.ifscCode}</span>}
            </div>
    
            <div className="form-group">
                <label>Account Holder Name</label>
                <input
                    type="text"
                    name="accountHolderName"
                    value={mediformData.accountHolderName}
                    placeholder='Enter Your Account Holder Name'
                    onChange={handleInputChange}
                />
                {errors.accountHolderName && <span className="error-message">{errors.accountHolderName}</span>}
            </div>
        </>
    );

    
    return (
        <div className="profile-container">
            <div className="profile-header">
                <h2>{isEditing ? 'Edit Profile' : 'Profile Details'}</h2>
            </div>
            <div className="profile-body">
                {isEditing ? (
                    <>
                        <div className="form-steps">
                            {currentStep === 1 && renderMedicalStep1()}
                            {currentStep === 2 && renderMedicalStep2()}
                        </div>
                        <div className="form-navigation">
                            <button
                                type="button"
                                className="nav-button"
                                onClick={() => setCurrentStep(currentStep - 1)}
                                disabled={currentStep === 1}
                            >
                                Previous
                            </button>
                            <button
                                type="button"
                                className="nav-button"
                                onClick={() => setCurrentStep(currentStep + 1)}
                                disabled={currentStep === 2}
                            >
                                Next
                            </button>
                            <button
                                type="button"
                                className="cancel-button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="save-button"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="profile-info">
                        <img
                            src={pharmaDetail.img || 'default-profile.jpg'}
                            alt="Medical"
                            className="profile-image"
                        />
                        <p><strong>Name:</strong> {pharmaDetail.name}</p>
                        <p><strong>Email:</strong> {pharmaDetail.email}</p>
                        <p><strong>Phone Number:</strong> {pharmaDetail.phoneNumber}</p>
                        <p><strong>License Number:</strong> {pharmaDetail.licenseNo}</p>
                        <p><strong>Address:</strong> {pharmaDetail.address}</p>
                        <p><strong>Location:</strong> {pharmaDetail.location}</p>
                        <p><strong>Bank Name:</strong> {pharmaDetail.bankName}</p>
                        <p><strong>Account Number:</strong> {pharmaDetail.accountNumber}</p>
                        <p><strong>IFSC Code:</strong> {pharmaDetail.ifscCode}</p>
                        <p><strong>Account Holder Name:</strong> {pharmaDetail.accountHolderName}</p>
                        <button className="edit-button" onClick={() => setIsEditing(true)}>
                            Edit
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MediProfile;