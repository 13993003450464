import React, { useState, useEffect, useRef } from 'react';
import orderIdIcon from '../assets/images/OrderId.svg';
import orderTimer from '../assets/images/OrderTimer.svg';
import modalTimer from '../assets/images/time-green.svg';
import successAnimation from '../assets/images/OrderResponse.webm';
import OrderConfirm from '../assets/images/OrderConfirm.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign, faMinus } from '@fortawesome/free-solid-svg-icons';
import './component.css';
import { io } from 'socket.io-client';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import axiosURL from '../config';

const OrderCard = ({ index, orderId, datetime, products, quantity, totalAmount, prescriptionImg }) => {
    const [pharmaId, setPharmaId] = useState(localStorage.getItem('pharmaId') || '');
    const [timer, setTimer] = useState(120);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [orderConfirm, setOrderConfirm] = useState(false)
    const [showPrescription, setShowPrescription] = useState(true);
    const [selectedMedicines, setSelectedMedicines] = useState([]);
    const socketRef = useRef(null);
    const [medicine, setMedicine] = useState([]);
    const [modalImage, setModalImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    totalAmount = parseFloat(totalAmount).toFixed(2);
    const platformFeePercentage = 5;
    const platformFee = (totalAmount * platformFeePercentage) / 100;
    const amountEarned = totalAmount - platformFee;

    const handleImageClick = (imgSrc) => {
        setModalImage(imgSrc);
        setIsModalOpen(true);
    };

    useEffect(() => {
        const fetchAndSetProducts = async () => {
            if (products && Array.isArray(products)) {
                try {
                    await fetchProductDetails(products);
                } catch (error) {
                    console.error('Error processing products:', error);
                }
            }
        };
        fetchAndSetProducts();
    }, [products]);

    const fetchProductDetails = async (productIds) => {
        try {
            const productsData = [];
            for (const id of productIds) {
                const response = await axiosURL.get('product/getbyid', {
                    params: { id: id }
                });
                if (response.status === 200) {
                    productsData.push(response.data[0]);
                } else {
                    console.error(`Failed to fetch details for product ID: ${id}`);
                }
            }
            setMedicine(productsData);
        } catch (error) {
            console.error('Error fetching product details:', error);
            setMedicine([]);
        }
    };

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
        }, 1000);

        return () => clearInterval(countdown);
    }, []);

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setShowModal(false);
            }
        };
        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io('https://api.immplus.in');
            socketRef.current.on('orderAssigned', (data) => {
                setShowOrderModal(true);
            });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.off('orderAssigned');
                socketRef.current.disconnect(); // Disconnect WebSocket on cleanup
                socketRef.current = null;
            }
        };
    }, []);

    const handleBackdropClick = (event) => {
        if (event.target.classList.contains('modal')) {
            setShowModal(false);
            setShowOrderModal(false);
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const handleExpandOrder = () => {
        setShowModal(true);
    };

    const handleRequestAcceptOrder = async () => {
        try {
            const response = await axiosURL.post(
                'order/request',
                {
                    orderId: orderId,
                    pharmacyId: Number(pharmaId),
                    products: selectedMedicines.map((medicine) => medicine._id),
                    quantity: selectedMedicines.map((medicine) => medicine.Quantity),
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const result = response.data;

            if (result.status === 'success') {
                setShowSuccessModal(true);
                setShowModal(false);
            } else {
                console.error(result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleGoToHome = () => {
        setShowSuccessModal(false);
    };

    const OrderDetails = () => {
        setShowSuccessModal(false);
        setShowOrderModal(false);
    }

    const handleStatusOrder = () => {
        setShowModal(false);
        setOrderConfirm(false);
    }

    const handleMedicineSelection = (medicine) => {
        setSelectedMedicines((prevSelectedMedicines) => {
            const isSelected = prevSelectedMedicines.some((m) => m._id === medicine._id);
            if (isSelected) {
                return prevSelectedMedicines.filter((m) => m._id !== medicine._id);
            } else {
                return [...prevSelectedMedicines, medicine];
            }
        });
    };

    return (
        <div>
            <div className="order-card">
                <div className="order-info">
                    <div className="order-id">
                        <div className="order-id-icon">
                            <img src={orderIdIcon} style={{ width: '30px', height: '30px' }} alt="Order ID Icon" />
                        </div>
                        <div>
                            <span>{orderId}</span>
                            <div className="order-date-time">
                                <span>{datetime}</span>
                            </div>
                        </div>
                    </div>
                    <div className="order-timer">
                        <div className="order-timer-icon">
                            <img src={orderTimer} alt="Order Timer Icon" height={20} />
                        </div>
                        <span>{formatTime(timer)}</span>
                    </div>
                </div>
                <button className="expand-order-btn" onClick={handleExpandOrder}>Expand Order</button>
            </div>

            {showModal && (
                <div className="modal" onClick={handleBackdropClick}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: 'none' }}>
                            <div className="order-info">
                                <div className="order-id">
                                    <div className="order-id-icon">
                                        <img src={orderIdIcon} style={{ width: '30px', height: '30px' }} alt="Order ID Icon" />
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: '600' }}>{orderId}</span>
                                        <div className="order-date-time" style={{ fontWeight: '600' }}>
                                            <span>{datetime}</span>
                                        </div>
                                    </div>
                                </div>
                                {!orderConfirm && (
                                    <div className="order-timer">
                                        <div className="order-timer-icon">
                                            <img src={modalTimer} height={20} alt="Order Timer Icon" />
                                        </div>
                                        <span style={{ color: '#5ca941', fontWeight: '600' }}>{formatTime(timer)}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-body">
                            {prescriptionImg && (
                                <div className="prescription-section">
                                    <button onClick={() => setShowPrescription(!showPrescription)}>
                                        {showPrescription ? 'Hide Prescription Image' : 'Show Prescription Image'}
                                    </button>
                                    {showPrescription && (
                                        <div className="prescription-img-container">
                                            {prescriptionImg.map((img, index) => (
                                                <img
                                                    key={index}
                                                    src={`https://api.immplus.in/${img}`}
                                                    alt={`Prescription ${index + 1}`}
                                                    className="prescription-image"
                                                    onClick={() => handleImageClick(`https://api.immplus.in/${img}`)}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    <Modal
                                        isOpen={isModalOpen}
                                        onRequestClose={() => setIsModalOpen(false)}
                                        contentLabel="Prescription Image"
                                        className="modal-content1"
                                        overlayClassName="modal-overlay1"
                                    >
                                        <button onClick={() => setIsModalOpen(false)} className="modal-close-button1">
                                            &times;
                                        </button>
                                        {modalImage && (
                                            <img
                                                src={modalImage}
                                                alt="Large Prescription View"
                                                className="modal-image"
                                            />
                                        )}
                                    </Modal>
                                </div>
                            )}
                            <table className="medicine-table">
                                <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th style={{ textAlign: 'left' }}>Items</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {medicine.map((medicine, index) => (
                                        <tr key={index}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    onChange={() => handleMedicineSelection(medicine)}
                                                    checked={selectedMedicines.includes(medicine)}
                                                />
                                            </td>
                                            <td style={{ textAlign: 'left' }}>{medicine.name}</td>
                                            <td>{medicine.Quantity}</td>
                                            <td>{medicine.MRP}</td>
                                            <td>{medicine.MRP}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='custom-hr'></div>
                            <div className="modal-summary">
                                <div className="summary-item">
                                    <span>Total Amount:</span>
                                    <span>
                                        <FontAwesomeIcon icon={faIndianRupeeSign} /> {totalAmount}
                                    </span>
                                </div>
                                <div className="summary-item">
                                    <span>Platform Fee ({platformFeePercentage}%):</span>
                                    <span>
                                        <FontAwesomeIcon icon={faMinus} /> <FontAwesomeIcon icon={faIndianRupeeSign} /> {platformFee}
                                    </span>
                                </div>
                                <div className="summary-item">
                                    <span>Amount Earned:</span>
                                    <span style={{ fontSize: '24px', fontWeight: '700' }}>
                                        <FontAwesomeIcon icon={faIndianRupeeSign} />{amountEarned}
                                    </span>
                                </div>
                                {orderConfirm && (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span className='OrderConfirm'><img src={OrderConfirm} alt="OrderConfirm" /></span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            {orderConfirm ? (
                                <button className="modal-button" onClick={handleStatusOrder}>
                                    Okay
                                </button>
                            ) : (
                                <>
                                    <button className="modal-button" onClick={handleRequestAcceptOrder}>
                                        Accept Order
                                    </button>
                                    <button className="modal-button cancel" onClick={() => setShowModal(false)}>
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {showSuccessModal && (
                <div className="modal" onClick={handleBackdropClick}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="success-animation-container">
                                <video className="success-animation" autoPlay loop muted>
                                    <source src={successAnimation} type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="modal-summary">
                                <h2 style={{ color: '#5ca941' }}>Order Accepted</h2>
                                <p style={{ fontWeight: '600' }}>{orderId}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="modal-button" onClick={handleGoToHome}>
                                Go to Home
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showOrderModal && (
                <div className="modal" onClick={handleBackdropClick}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="success-animation-container">
                                <video className="success-animation" autoPlay loop muted>
                                    <source src={successAnimation} type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="modal-summary">
                                <h2 style={{ color: '#5ca941' }}>New Order Assigned</h2>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="modal-button" onClick={OrderDetails}>
                                Okay
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderCard;