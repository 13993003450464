import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import LoginPage from './Login';
import RegisterPage from './Register';

import MedicalDashboard from './Medical-Dashboard/medicaldash';
import MyOrder from './Medical-Dashboard/myorder';
import Payment from './Medical-Dashboard/payment';
import MediProfile from './Medical-Dashboard/About/profile';
import MedicalDash from './Medical-Dashboard/dashboard';
import AboutUs from './Medical-Dashboard/About/about';
import PrivacyPolicy from './Medical-Dashboard/About/privacy';
import TermsAndConditions from './Medical-Dashboard/About/terms&condition';

import './App.css';
import VerificationPendingPage from './Pending';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    const storedUserType = localStorage.getItem('userType');

    if (storedLoginStatus === 'true') {
      setIsLoggedIn(true);
      setUserType(storedUserType);
    } else {
      setIsLoggedIn(false);
      setUserType('');
    }
  }, []);

  const handleLogin = (status, userType) => {
    setIsLoggedIn(status);
    setUserType(userType);
    localStorage.setItem('isLoggedIn', status.toString());
    localStorage.setItem('userType', userType);

    if (status) {
      if (userType === 'medical') {
        navigate('/medical-dashboard/dashboard', { replace: true });
      } else {
        navigate('/');
      }
    } else {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('userType');
      localStorage.removeItem('pharmaId');
      navigate('/login');
    }
  };

  return (
    <Routes>
      <Route path="/login" element={!isLoggedIn ? <LoginPage onLogin={handleLogin} /> : <Navigate to="/medical-dashboard/dashboard" replace />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/pending" element={<VerificationPendingPage />} />

      <Route path="/medical-dashboard" element={isLoggedIn && userType === 'medical' ? <MedicalDashboard /> : <Navigate to="/login" replace />}>
        <Route path="dashboard" index element={<MedicalDash />} />
        <Route path="myorder" element={<MyOrder />} />
        <Route path="payment" element={<Payment />} />
        <Route path="profile" element={<MediProfile />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="terms&conditions" element={<TermsAndConditions />} />
      </Route>

      <Route path="*" element={<Navigate to={isLoggedIn ? "/medical-dashboard/dashboard" : "/login"} replace />} />
    </Routes>
  );
}

export default App;
