import React from 'react';
import './main.css';
import axiosURL from './config';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animationData from './assets/animations/Pending.json';

const VerificationPendingPage = () => {
  const navigate = useNavigate();

  const handleCheckStatus = async () => {
    const userId = Number(localStorage.getItem('id'));
    if (!userId) {
      Swal.fire({
        title: 'error',
        text: 'Your verification is still pending. Please check back later.',
        icon: 'info',
        customClass: {
          confirmButton: 'swal-button-pending' // Custom class for pending status
        }
      });
      return;
    }

    let apiUrl = '';

    if (localStorage.getItem('userType') === 'doctor') {
      apiUrl = `docter/getById?id=${userId}`;
    } else if (localStorage.getItem('userType') === 'medical') {
      apiUrl = `pharma/getPharmaById?id=${userId}`;
    }

    try {
      const response = await axiosURL.get(apiUrl);

      if (response.status === 200) {
        const data = response.data[0];

        if (data.isApproved === 0) {
          Swal.fire({
            title: 'Pending',
            text: 'Your verification is still pending. Please check back later.',
            icon: 'info',
            customClass: {
              confirmButton: 'swal-button-pending' // Custom class for pending status
            }
          });
        } else if (data.isApproved === 1) {
          Swal.fire({
            title: 'Approved',
            text: 'Your verification has been approved! You can now access your account.',
            icon: 'success',
            confirmButtonText: 'Login Now',
            customClass: {
              confirmButton: 'swal-button-approve'
            }
          }).then(() => {
            navigate('/login');
          });
        } else if (data.isApproved === 2) {
          Swal.fire({
            title: 'Rejected',
            text: 'Your verification has been rejected. Please contact support for further assistance.',
            icon: 'warning',
            customClass: {
              confirmButton: 'swal-button-reject'
            }
          })
        } else {
          Swal.fire({
            title: 'Unexpected Status',
            text: 'Unexpected verification status. Please try again later.',
            icon: 'error',
            customClass: {
              confirmButton: 'swal-button-unexpected' // Custom class for unexpected status
            }
          });
        }
      } else {
        Swal.fire({
          title: 'Failed',
          text: 'Failed to check status. Please try again later.',
          icon: 'error',
          customClass: {
            confirmButton: 'swal-button-failed' // Custom class for failed status
          }
        });
      }
    } catch (error) {
      console.error('Error checking status:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while checking your status. Please try again later.',
        icon: 'error',
        customClass: {
          confirmButton: 'swal-button-error' // Custom class for error
        }
      });
    }
  };

  return (
    <div className="container9">
      <h1 className="message">Your verification is pending</h1>
      <div style={{
        height: '30%'
      }}>
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          style={{ height: '100%', width: '100%' }}
        />
      </div>
      <p className="description">
        Please wait while we verify your details. This may take a few moments.
      </p>
      <button className="check-status-button" onClick={handleCheckStatus}>
        Check Status
      </button>
    </div>
  );
};

export default VerificationPendingPage;
