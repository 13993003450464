import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy</h1>
            <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our services.</p>
            
            <h2>Information We Collect</h2>
            <p>We may collect personal information such as your name, email address, phone number, and medical history when you register on our website or use our services. We also collect non-personal information such as browser type, IP address, and usage data.</p>
            
            <h2>How We Use Your Information</h2>
            <p>We use your personal information to provide and improve our services, communicate with you, and for billing purposes. We may also use your information to send you promotional materials or updates about our services.</p>
            
            <h2>Data Security</h2>
            <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, or misuse. However, no security system is impenetrable, and we cannot guarantee the security of your data.</p>
            
            <h2>Changes to This Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review the policy periodically.</p>
            
            <h2>Contact Us</h2>
            <p>If you have any questions about our Privacy Policy or how we handle your personal information, please contact us at [Contact Email].</p>
        </div>
    );
};

export default PrivacyPolicy;
