import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarImg from "../assets/images/ScheduleCalender.svg";
import HorImg from '../assets/images/horizontal.svg';
import VerImg from '../assets/images/vertical.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEye, faCheckCircle, faTimesCircle, faIndianRupeeSign, faMinus, faClock } from '@fortawesome/free-solid-svg-icons';
import orderIdIcon from '../assets/images/OrderId.svg';
import '../pharma.css';
import axios from 'axios';
import axiosURL from '../config';

const Payment = () => {

  const [pharmaId, setPharmaId] = useState(localStorage.getItem('pharmaId') || '');
  const [showModal, setShowModal] = useState(false);
  const [activeButton, setActiveButton] = useState('Button1');
  const [activeFilterButton, setActiveFilterButton] = useState('All');
  const [startDate, setStartDate] = useState(null);
  const [orderData, setOrderData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [medicine, setMedicine] = useState([]);

  const datePickerRef = useRef(null);
  const today = new Date();

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        if (!pharmaId) {
          console.error('Pharma ID is not defined.');
          return;
        }

        const paymentResponse = await axiosURL.get('paymentorder/records');
        const payments = paymentResponse.data || [];
        const filteredPayments = payments.filter(payment => payment.PartnerId == pharmaId);
        setOrderData(filteredPayments);
        setFilteredData(filteredPayments);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };

    fetchPayments();
  }, [pharmaId]);

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return 'Unpaid';
      case 1:
        return 'Processing';
      case 2:
        return 'Paid';
      default:
        return 'Unknown';
    }
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    if (date) {
      const filtered = orderData.filter((order) => {
        const orderDate = new Date(order.date);
        orderDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);

        return orderDate.getTime() === date.getTime();
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(orderData);
    }
  };

  const handleClick = (buttonName) => {
    if (activeButton !== buttonName) {
      setActiveButton(buttonName);
    }
  };

  const handleFilterClick = (filterName) => {
    setActiveFilterButton(filterName);

    if (filterName === 'All') {
      setFilteredData(orderData);
    } else {
      const filtered = orderData.filter(order => getStatus(order.status).toLowerCase() === filterName.toLowerCase());
      setFilteredData(filtered);
    }
  };

  const clearAllFilters = () => {
    setStartDate(null);
    setActiveFilterButton('All');
    setSearchQuery('');
    setFilteredData(orderData);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query) {
      const filtered = orderData.filter(order =>
        order.orderId.toString().includes(query)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(orderData);
    }
  };

  const handleViewClick = async (id) => {
    try {
      if (!id) {
        console.error('Order ID is not defined.');
        return;
      }

      // Fetch order details from the API
      const response = await axiosURL.get(`order/id?id=${id}`);
      const orderDetails = response.data || {};

      const platformFeePercentage = 5;
      const platformFee = (orderDetails.totalPrice * platformFeePercentage) / 100;
      const amountEarned = orderDetails.totalPrice - platformFee;

      setModalData({
        ...orderDetails,
        platformFee,
        amountEarned,
      });

      setShowModal(true);
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains('modal')) {
      setShowModal(false);
    }
  };

  return (
    <div className="container mt-2">
      <div className="container-fluid">
        <div className="mb-3 topHeading">
          My<strong> Payments</strong>
        </div>
        <div className="d-flex">
          <button
            onClick={() => handleFilterClick('All')}
            className={`filterBtn ${activeFilterButton === 'All' ? 'active-filter' : ''}`}
          >
            All
          </button>
          <button
            onClick={() => handleFilterClick('paid')}
            className={`filterBtn ${activeFilterButton === 'paid' ? 'active-filter' : ''}`}
          >
            Payment Done
          </button>
          <button
            onClick={() => handleFilterClick('processing')}
            className={`filterBtn ${activeFilterButton === 'pending' ? 'active-filter' : ''}`}
          >
            Payment Pending
          </button>
          <button
            onClick={() => handleFilterClick('unpaid')}
            className={`filterBtn ${activeFilterButton === 'unpaid' ? 'active-filter' : ''}`}
          >
            Payment Not Done
          </button>
          <div className="search-bar-container">
            <button className="search-button">
              <FontAwesomeIcon icon={faSearch} />
            </button>
            <input
              type="text"
              placeholder="Search..."
              className="search-input"
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
          <div className="date-picker-container" style={{ marginLeft: '10px' }}>
            <DatePicker
              ref={datePickerRef}
              selected={startDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="date-picker"
              placeholderText="Search By Date"
              minDate={today}
            />
            <img src={calendarImg} alt="" className="calendar-icon" onClick={() => datePickerRef.current.setOpen(true)} />
          </div>

          <div
            style={{ marginLeft: '10px' }}
            onClick={() => handleClick('Button1')}
            className={`toggleBtn ${activeButton === 'Button1' ? 'active primary' : 'inactive'}`}
          >
            <img src={HorImg} className='mediumIcon' />
          </div>
          <div
            onClick={() => handleClick('Button2')}
            className={`toggleBtn ${activeButton === 'Button2' ? 'active primary' : 'inactive'}`}
          >
            <img src={VerImg} className='mediumIcon' />
          </div>
          <button onClick={clearAllFilters} className="clearfilterBtn">
            Clear Filter
          </button>
        </div>

        <div className="PaymentTable">
          <table className="table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Order Id</th>
                <th style={{ textAlign: 'left' }}>Date</th>
                <th>Total Price</th>
                <th>Amount Earned</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {(filteredData && Array.isArray(filteredData) && filteredData.length > 0) ? (
                filteredData.map((order, index) => (
                  <tr key={order.orderId}>
                    <td data-label="No." style={{ fontWeight: '400' }}>{index + 1}</td>
                    <td data-label="Order Id" style={{ fontWeight: '500' }}>{order.orderId}</td>
                    <td data-label="Date" style={{ fontWeight: '500', color: '#000000D2', textAlign: 'left' }}>{formatDate(order.date)}</td>
                    <td data-label="Total Price" style={{ fontWeight: '600' }}>₹{parseFloat(order.totalPrice).toFixed(2)}</td>
                    <td data-label="Amount Earned" style={{ fontWeight: '600' }}>{parseFloat(order.amountToBePaid).toFixed(2)}</td>
                    <td data-label="Status">
                      <div className={`status-box status-${getStatus(order.status).toLowerCase()}`}>
                        {getStatus(order.status) === 'Paid' ? (
                          <FontAwesomeIcon icon={faCheckCircle} />
                        ) : getStatus(order.status) === 'Processing' ? (
                          <FontAwesomeIcon icon={faClock} />
                        ) : (
                          <FontAwesomeIcon icon={faTimesCircle} />
                        )}
                        {' '}{getStatus(order.status)}
                      </div>
                    </td>
                    <td data-label="Actions">
                      <button className="btn btn-info btn-sm" style={{ borderRadius: 10 }} onClick={() => handleViewClick(order.orderId)}>
                        <FontAwesomeIcon icon={faEye} style={{ color: '#007CD7' }} /> View
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {showModal && modalData && (
          <div className="modal" onClick={handleBackdropClick}>
            <div className="modal-content">
              <div className="modal-header" style={{ background: 'none' }}>
                <div className="order-info">
                  <div className="order-id">
                    <div className="order-id-icon">
                      <img src={orderIdIcon} style={{ width: '30px', height: '30px' }} alt="Order ID Icon" />
                    </div>
                    <div>
                      <span style={{ fontWeight: '600' }}>{modalData._id}</span> {/* Assuming _id is the order ID */}
                      <div className="order-date-time" style={{ fontWeight: '600' }}>
                        <span>{formatDate(modalData.date)}</span> {/* Format the date as needed */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <table className="medicine-table">
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left' }}>Items</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalData.products.map((medicine, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: 'left' }}>{medicine.name}</td>
                        <td>{modalData.quantity[index]}</td>
                        <td>{medicine.MRP}</td>
                        <td>{medicine.MRP}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className='custom-hr'></div>
                <div className="modal-summary">
                  <div className="summary-item">
                    <span>Total Amount:</span>
                    <span>
                      <FontAwesomeIcon icon={faIndianRupeeSign} /> {parseFloat(modalData.totalPrice).toFixed(2)}
                    </span>
                  </div>
                  <div className="summary-item">
                    <span>Platform Fee ({modalData.platformFeePercentage}%):</span>
                    <span>
                      <FontAwesomeIcon icon={faMinus} /> <FontAwesomeIcon icon={faIndianRupeeSign} /> {parseFloat(modalData.platformFee).toFixed(2)}
                    </span>
                  </div>
                  <div className="summary-item">
                    <span>Amount Earned:</span>
                    <span style={{ fontSize: '24px', fontWeight: '700' }}>
                      <FontAwesomeIcon icon={faIndianRupeeSign} /> {parseFloat(modalData.amountEarned).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Payment;