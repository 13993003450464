import React, { useState, useEffect, useRef } from 'react';
import totalOrder from "../assets/images/totalOrder.svg";
import todayOrder from "../assets/images/orderToday.svg";
import ordersOngoing from "../assets/images/ordersOngoing.svg";
import todayEarning from "../assets/images/todayEarning.svg";
import '../pharma.css';
import OrderCard from '../components/Order';
import OngoingOrderCard from '../components/OngoingOrder';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import axiosURL from '../config';

const MedicalDash = () => {
  const [pharmaId, setPharmaId] = useState(localStorage.getItem('pharmaId') || '');
  const [dashData, setDashData] = useState([]);
  const [pharmaDetails, setPharmaDetails] = useState({});
  const [orders, setOrders] = useState([]);
  const [ongoingorder, setOngoingOrder] = useState([]);
  const [orderId, setOrderId] = useState('');
  const socketRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://api.immplus.in');
      
      const handleNewOrder = async (order) => {
        if (order && order.orderId) {
          setOrderId(order.orderId);
          fetchOrder();
          //playNotificationSound();
        } else {
          console.error('Invalid order data:', order);
        }
      };

      const handleOrderConfirmed = async (order) => {
        if (order && order.orderId) {
          setOrderId(order.orderId);
          fetchOngoingOrder();
        } else {
          console.error('Invalid order data:', order);
        }
      };

      const handleOrderStatusChanged = async (order) => {
        if (order && order.orderId) {
          fetchOngoingOrder();
        } else {
          console.error('Invalid order data:', order);
        }
      };

      socketRef.current.on('newOrder', handleNewOrder);
      socketRef.current.on('orderConfirmed', handleOrderConfirmed);
      socketRef.current.on('orderStatusChanged', handleOrderStatusChanged);
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.off('newOrder');
        socketRef.current.off('orderConfirmed');
        socketRef.current.off('orderStatusChanged');
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  useEffect(() => {

    if (!pharmaId) {
      console.error('No doctor ID found in local storage');
      return;
    }
    fetchDashData();
    fetchOrder();
    fetchOngoingOrder();
    fetchPharmaDetails();
  }, [pharmaId]);

  const fetchPharmaDetails = async () => {
    try {
        if (!pharmaId) {
            console.error('No Pharma ID found in local storage');
            return;
        }
        const url = `pharma/getPharmaById?id=${pharmaId}`;
        const response = await axiosURL.get(url);
        setPharmaDetails(response.data[0]);
    } catch (error) {
        console.error('Error fetching pharma details:', error);
    }
};

  const fetchDashData = async () => {
    try {
      const response = await axiosURL.get(`pharma/dashboard`, {
        params: { id: pharmaId }
      });
      if (response.data) {
        setDashData(response.data);
      } else {
        console.error('Unexpected API response format');
      }
    } catch (error) {
      console.error('Failed to fetch Appointment', error);
    }
  };

  const fetchOrder = async () => {
    try {
      const response = await axiosURL.get(`order/getAvailable`);
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching ongoing orders:', error);
    }
  };

  const fetchOngoingOrder = async () => {
    try {
      const response = await axiosURL.get(`pharma/ongoingOrder`, {
        params: { id: pharmaId }
      });
      setOngoingOrder(response.data.reverse());
    } catch (error) {
      console.error('Error fetching ongoing orders:', error);
    }
  };

  const formatDateTime = (dateString) => {
    const optionsDate = { day: '2-digit', month: 'short', year: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-GB', optionsTime);
    return `${formattedTime}, ${formattedDate}`;
};

  return (
    <div className="container mt-2">
      <div className="container-fluid">
        <div className="mb-3 topHeading">
          Hello, <strong>{pharmaDetails.name} Pharmacy</strong>
        </div>
        <div className="row appointment-summary">
          <div className="col-12 col-md-3 mb-3">
            <div className="summary-box h-100 d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center">
                <img src={totalOrder} alt="Total Appointments" className="summary-icon" />
                <div className="d-flex summary-text">
                  <p>{dashData.totalOrders}</p>
                  <h5>Total Orders</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 mb-3">
            <div className="summary-box h-100 d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center">
                <img src={todayOrder} alt="Today's Appointments" className="summary-icon" />
                <div className="summary-text">
                  <p>{dashData.todayOrder}</p>
                  <h5>Today's Orders</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 mb-3">
            <div className="summary-box h-100 d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center">
                <img src={ordersOngoing} alt="Upcoming Appointments" className="summary-icon" />
                <div className="summary-text">
                  <p>{dashData.runingOrder}</p>
                  <h5>Ongoing Orders</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 mb-3">
            <div className="h-100 d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center">
                <img src={todayEarning} alt="Completed Appointments" className="summary-icon" />
                <div className="summary-text">
                  <p>{dashData.money}</p>
                  <h5>Today's Earning</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-schedule-container mt-4">
          <span className="my-schedule-text">Orders Available</span>
        </div>
        <div className="order-container">
          {orders.map((order) => (
            <OrderCard
              key={order._id}
              orderId={order._id}
              dateTime={formatDateTime(order.date)}
              products={order.products}
              quantity={order.quantity}
              totalAmount={order.totalPrice}
              prescriptionImg={order.prescriptionImg}/>
          ))}
        </div>
        <div className="my-schedule-container mt-4">
          <span className="my-schedule-text">Ongoing Orders</span>
        </div>
        <div className="order-container">
          {ongoingorder.map((order) => (
            <OngoingOrderCard
              key={order._id}
              orderId={order._id}
              dateTime={formatDateTime(order.date)}
              medicines={order.products}
              quantity={order.quantity}
              totalAmount={order.totalPrice}
              status={order.status}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MedicalDash;
