import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions-container">
            <h1>Terms and Conditions</h1>
            <p>Welcome to [Your Medical Website Name]. By using our website and services, you agree to comply with and be bound by the following terms and conditions.</p>
            
            <h2>Use of the Website</h2>
            <p>You agree to use our website only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the website.</p>
            
            <h2>Intellectual Property</h2>
            <p>All content, trademarks, and other intellectual property on our website are the property of [Your Medical Website Name]. You may not use, reproduce, or distribute any content without our express written permission.</p>
            
            <h2>Limitation of Liability</h2>
            <p>We are not liable for any direct, indirect, incidental, or consequential damages arising from the use of our website or services. We make no warranties or representations about the accuracy or completeness of the content on our website.</p>
            
            <h2>Changes to Terms</h2>
            <p>We reserve the right to modify these terms and conditions at any time. Any changes will be posted on this page, and your continued use of the website constitutes your acceptance of the revised terms.</p>
            
            <h2>Governing Law</h2>
            <p>These terms and conditions are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</p>
            
            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about our Terms and Conditions, please contact us at [Contact Email].</p>
        </div>
    );
};

export default TermsAndConditions;
