import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarImg from "../assets/images/ScheduleCalender.svg";
import HorImg from '../assets/images/horizontal.svg';
import VerImg from '../assets/images/vertical.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import OngoingOrderCard from '../components/OngoingOrder';
import PaymentCard from '../components/PaymentCard';
import '../pharma.css';
import axios from 'axios';
import axiosURL from '../config';

const sampleOrders = {};

function MyOrder() {
  const [pharmaId, setPharmaId] = useState(localStorage.getItem('pharmaId') || '');

  const [activeButton, setActiveButton] = useState('Button1');
  const [activeFilterButton, setActiveFilterButton] = useState('All');
  const [startDate, setStartDate] = useState(null);
  const [orderData, setOrderData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const datePickerRef = useRef(null);
  const today = new Date();

  useEffect(() => {
    fetchOrder();
  }, []);

  const fetchOrder = async () => {
    try {
      const response = await axiosURL.get(`pharma/getOrderById`, {
        params: { id: pharmaId }
      });
      setOrderData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Error fetching ongoing orders:', error);
    }
  }

  const handleDateChange = (date) => {
    setStartDate(date);
    if (date) {
      const filtered = orderData.filter((order) => {
        const orderDate = new Date(order.date);
        orderDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);

        return orderDate.getTime() === date.getTime();
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(orderData);
    }
    console.log(filteredData);

  };

  const handleClick = (buttonName) => {
    if (activeButton !== buttonName) {
      setActiveButton(buttonName);
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return 'Unpaid';
      case 1:
        return 'Processing';
      case 2:
        return 'Paid';
      default:
        return 'Unknown';
    }
  };

  const handleFilterClick = (filterName) => {
    setActiveFilterButton(filterName);
  
    if (filterName === 'All') {
      setFilteredData(orderData);
    } else {
      const filteredOrders = orderData.map(dateEntry => {
        const filteredInfo = (dateEntry.info || []).filter(order =>
          getStatus(order.status).toLowerCase() === filterName.toLowerCase()
        );
  
        return filteredInfo.length > 0 ? { ...dateEntry, info: filteredInfo } : null;
      }).filter(entry => entry !== null);
  
      setFilteredData(filteredOrders);
    }
  };  

  const clearAllFilters = () => {
    setStartDate(null);
    setActiveFilterButton('All');
    setSearchQuery('');
    setFilteredData(orderData);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  
    if (query) {
      const filtered = orderData.map(order => ({
        ...order,
        info: order.info.filter(item =>
          item._id.toString().includes(query)
        )
      })).filter(order => order.info.length > 0);
  
      setFilteredData(filtered);
    } else {
      setFilteredData(orderData);
    }
  };  

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', options);
    const formattedTime = date.toLocaleTimeString('en-GB', optionsTime);
    return `${formattedTime}, ${formattedDate}`;
  };

  return (
    <div className="container mt-2">
      <div className="container-fluid">
        <div className="mb-3 topHeading">
          My<strong> Orders</strong>
        </div>
        <div className="d-flex">
          <button
            onClick={() => handleFilterClick('All')}
            className={`filterBtn ${activeFilterButton === 'All' ? 'active-filter' : ''}`}
          >
            All
          </button>
          <button
            onClick={() => handleFilterClick('paid')}
            className={`filterBtn ${activeFilterButton === 'paid' ? 'active-filter' : ''}`}
          >
            Payment Done
          </button>
          <button
            onClick={() => handleFilterClick('processing')}
            className={`filterBtn ${activeFilterButton === 'pending' ? 'active-filter' : ''}`}
          >
            Payment Pending
          </button>
          <button
            onClick={() => handleFilterClick('unpaid')}
            className={`filterBtn ${activeFilterButton === 'unpaid' ? 'active-filter' : ''}`}
          >
            Payment Not Done
          </button>
          <div className="search-bar-container">
            <button className="search-button">
              <FontAwesomeIcon icon={faSearch} />
            </button>
            <input
              type="text"
              placeholder="Search..."
              className="search-input"
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
          <div className="date-picker-container" style={{ marginLeft: '10px' }}>
            <DatePicker
              ref={datePickerRef}
              selected={startDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="date-picker"
              placeholderText="Search By Date"
              minDate={today}
            />
            <img src={calendarImg} alt="" className="calendar-icon" onClick={() => datePickerRef.current.setOpen(true)} />
          </div>

          <div
            style={{ marginLeft: '10px' }}
            onClick={() => handleClick('Button1')}
            className={`toggleBtn ${activeButton === 'Button1' ? 'active primary' : 'inactive'}`}
          >
            <img src={HorImg} className='mediumIcon' />
          </div>
          <div
            onClick={() => handleClick('Button2')}
            className={`toggleBtn ${activeButton === 'Button2' ? 'active primary' : 'inactive'}`}
          >
            <img src={VerImg} className='mediumIcon' />
          </div>
          <button onClick={clearAllFilters} className="clearfilterBtn">
            Clear Filter
          </button>
        </div>

        <div className="order-container-horizontal">
          {filteredData.map((dateGroup) => (
            <div className="order-group" key={dateGroup.date}>
              <div className="order-date-header">
                <h5>{dateGroup.date}</h5>
              </div>
              <div className="order-container-vertical">
                {dateGroup.info.map((order) => (
                  <PaymentCard
                    orderId={order._id}
                    dateTime={formatDate(order.date)}
                    medicines={order.products}
                    quantity={order.quantity}
                    location={order.location}
                    totalAmount={order.totalPrice}
                    status={order.status}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MyOrder;
