import React from 'react';

const AboutUs = () => {
    return (
        <div className="about-us-container">
            <h1>About Us</h1>
            <p>Welcome to [Your Medical Website Name], where we are dedicated to providing the best healthcare services. Our team of experienced doctors and healthcare professionals are committed to improving the health and well-being of our community.</p>
            
            <h2>Our Mission</h2>
            <p>Our mission is to deliver high-quality healthcare through compassionate care, innovative treatments, and personalized services. We aim to be a trusted partner in your health journey, offering expert advice and support every step of the way.</p>
            
            <h2>Our Team</h2>
            <p>Our team consists of board-certified doctors, specialists, and healthcare professionals who are passionate about providing exceptional care. With years of experience and a commitment to excellence, we are here to meet your healthcare needs.</p>
            
            <h2>Contact Us</h2>
            <p>If you have any questions or need further information, feel free to contact us at [Contact Email] or call us at [Contact Number]. We are here to help!</p>
        </div>
    );
};

export default AboutUs;
